import { useEffect, useState } from 'react'

import { Link, useLocation, useNavigate, useParams } from 'react-router-dom'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import styled from 'styled-components'

import { useData } from 'contexts/data'
import { useLanguage } from 'contexts/language'
import {
  getFrecsByProduct,
  getGroupsByProduct,
  postGroupsByProduct,
  removeGroupsByProduct,
  reorderGroupFrecsByProduct,
} from 'api'

import Box from 'components/box'
import Typography from 'components/typography'
import AnimateChildren from 'react-animate-children'
import TextInput from 'components/textinput'
import Button from 'components/button'

import deleteIcon from 'assets/icon/delete.png'

const ProductGroup = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }, [])

  const [language] = useLanguage()
  const [data, setData] = useData()

  const { product } = useParams()
  const { state: locationState } = useLocation()
  const navigate = useNavigate()

  const [state, setState] = useState({
    ...locationState,
  })

  const { group, frecs, isNewGroup, section, isSession } = state

  const [isEditing, setIsEditing] = useState(isNewGroup)

  useEffect(() => {
    setTimeout(() => {
      setIsEditing(true)
    }, 500)
  }, [])

  const groupsByProduct = getGroupsByProduct(product, section)
  const frecsByProduct = getFrecsByProduct(product, section)

  useEffect(() => {
    const initialValues = ['Nuevo Grupo', 'Descripción', 'Descripción Ampliada']

    const isNewGroup =
      group?.Nombre === initialValues[0] &&
      group?.Descripcion === initialValues[1] &&
      group?.DescripcionAmpliada === initialValues[2]

    if (!isNewGroup) {
      setIsEditing(false)
    }
  }, [])

  useEffect(() => {
    if (frecsByProduct[1]) {
      const newFrecs =
        data[product][frecsByProduct[0]][frecsByProduct[1]][language][
          group.groupsIndex
        ]

      if (JSON.stringify(newFrecs) !== JSON.stringify(frecs)) {
        setState({
          ...state,
          group: {
            ...group,
            frequencies: newFrecs,
          },
          frecs: newFrecs,
        })
        navigate('.', {
          replace: true,
          state: {
            ...state,
            group: {
              ...group,
              frequencies: newFrecs,
            },
            newFrecs,
          },
        })
      }
    }

    if (!frecsByProduct[1]) {
      let newFrecs
      let oldFrecs
      if (product === 'lb') {
        const newFacial = data[product][frecsByProduct[0]][language].findIndex(
          facial => facial.Nombre === group.Nombre,
        )

        newFrecs =
          data[product][frecsByProduct[0]][language][newFacial]?.frequencies
        oldFrecs = group?.frequencies
      } else {
        newFrecs = data[product][frecsByProduct[0]][language][group.groupsIndex]
        oldFrecs = frecs
      }

      if (JSON.stringify(newFrecs) !== JSON.stringify(oldFrecs)) {
        setState({
          ...state,
          group: {
            ...group,
            frequencies: newFrecs,
          },
          frecs: newFrecs,
        })
        navigate('.', {
          replace: true,
          state: {
            ...state,
            group: {
              ...group,
              frequencies: newFrecs,
            },
            newFrecs,
          },
        })
      }
    }
  }, [])

  const handleGroupChange = (newGroupValue, dataKey) => {
    const newGroup = {
      ...group,
      [dataKey]: newGroupValue,
    }

    setState({
      ...state,
      group: newGroup,
    })

    const newData = postGroupsByProduct(
      data,
      product,
      groupsByProduct,
      frecsByProduct,
      language,
      group,
      newGroup,
    )

    setData(newData)

    navigate('.', {
      replace: true,
      state: {
        ...state,
        group: newGroup,
      },
    })
  }

  const handleRemoveGroup = () => {
    if (confirm(`Estás seguro que quieres eliminar ${group.Nombre}?`)) {
      const newData = removeGroupsByProduct(
        data,
        product,
        groupsByProduct,
        frecsByProduct,
        language,
        group,
      )

      setData(newData)

      navigate(`/products/${product}`, {
        replace: true,
      })
    }
  }

  const handleReorderGroupFrecs = newGroupFrecs => {
    const newGroup = {
      ...group,
      frequencies: newGroupFrecs,
    }

    setState({
      ...state,
      group: newGroup,
      frecs: newGroupFrecs,
    })

    const newData = reorderGroupFrecsByProduct(
      data,
      product,
      groupsByProduct,
      frecsByProduct,
      language,
      group,
      newGroup,
      newGroupFrecs,
    )

    setData(newData)

    navigate('.', {
      replace: true,
      state: {
        ...state,
        group: newGroup,
        frecs: newGroupFrecs,
      },
    })
  }

  console.log(frecs)
  const mappedFrecs = (product === 'lb' ? group?.frequencies : frecs)?.filter(
    Boolean,
  )

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list)
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)

    return result
  }

  function onDragEnd(result) {
    if (!result.destination) {
      return
    }

    const newMappedFrecs = reorder(
      mappedFrecs,
      result.source.index,
      result.destination.index,
    )

    newMappedFrecs.forEach((frec, index) => {
      frec.frecIndex = index
      frec.groupIndex = index
    })

    handleReorderGroupFrecs(newMappedFrecs)
  }

  const getItemStyle = (_, draggableStyle) => ({
    ...draggableStyle,
  })

  let sessionTime = '0'
  if (isSession) {
    let totalSeconds = mappedFrecs?.reduce((acc, frec) => {
      return (
        acc +
        Number(
          frec?.Segments?.reduce((acc, segment) => {
            return acc + +segment.Duracion
          }, 0),
        )
      )
    }, 0)

    let hours = Math.floor(totalSeconds / 3600)
    totalSeconds %= 3600
    let minutes = Math.floor(totalSeconds / 60)
    let seconds = totalSeconds % 60

    minutes = String(minutes).padStart(2, '0')
    hours = String(hours).padStart(2, '0')
    seconds = String(seconds).padStart(2, '0')

    if (!isNaN(hours) && !isNaN(minutes) && !isNaN(seconds)) {
      sessionTime = `${hours}:${minutes}:${seconds}`
    }
  }

  return (
    <Container speed={50}>
      <FrecRemoveButton
        onClick={() => handleRemoveGroup()}
        noBorder
        hoverAnimation
      >
        <FrecRemoveImg src={deleteIcon} alt="delete-frec" />
      </FrecRemoveButton>

      <Title
        variant="bodylarge"
        dataKey="Nombre"
        onFinish={(value, dataKey) => handleGroupChange(value, dataKey)}
        fontSize={16}
        defaultEditing={isNewGroup}
        disableEditing={!isEditing}
      >
        {group.Nombre}
      </Title>

      {!isSession && (
        <Subtitle
          variant="bodylarge"
          dataKey={
            group.Descripcion ? 'Descripcion' : group.Archivo ? 'Archivo' : ''
          }
          onFinish={(value, dataKey) => handleGroupChange(value, dataKey)}
          fontSize={16}
          defaultEditing={isNewGroup}
          disableEditing={!isEditing}
        >
          {group.Descripcion || group.Archivo}
        </Subtitle>
      )}

      <Description
        variant="bodylarge"
        dataKey={
          typeof group.DescripcionAmpliada === 'string'
            ? 'DescripcionAmpliada'
            : typeof group.Observaciones === 'string'
            ? 'Observaciones'
            : ''
        }
        onFinish={(value, dataKey) => handleGroupChange(value, dataKey)}
        fontSize={16}
        defaultEditing={isNewGroup}
        disableEditing={!isEditing}
      >
        {group.DescripcionAmpliada || group.Observaciones || '_'}
      </Description>

      {isSession ? (
        <Typography variant="bodylarge">Tiempo total: {sessionTime}</Typography>
      ) : null}

      <FrecsContainer>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable">
            {(provided, snapshot) => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {mappedFrecs?.map(({ Nombre, Id, ...rest }, index) => {
                  const { frecIndex, ...restWithoutIndex } = rest

                  return (
                    <Draggable
                      key={`${index}-${Id}`}
                      draggableId={`${index}-${Id}`}
                      // isDragDisabled={true}
                      index={index}
                    >
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={getItemStyle(
                            snapshot.isDragging,
                            provided.draggableProps.style,
                          )}
                        >
                          <Link
                            to={`/products/${product}/group/${group.groupFrecsIndex}/frec/${index}`}
                            state={{
                              group,
                              frec: {
                                Nombre,
                                Id,
                                frecIndex: index,
                                ...restWithoutIndex,
                              },
                              section,
                              isSession,
                            }}
                          >
                            <FrecsTitle variant="bodylarge">
                              {Nombre}
                            </FrecsTitle>
                          </Link>
                        </div>
                      )}
                    </Draggable>
                  )
                })}

                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>

        <Link
          to={`/products/${product}/group/${group.groupFrecsIndex}/frec/new`}
          state={{
            group,
            section,
            frec: {
              Id:
                Number(
                  [...new Set(mappedFrecs?.map(({ Id }) => Id))]
                    .sort((a, b) => a - b)
                    .at(-1),
                ) + 1 || 0,
              frecIndex: mappedFrecs?.length ? mappedFrecs?.length : 0,
              Nombre: '',
              Summary: '',
              Mode: '0',
              Segundos: '0',
              Trama: '',
              Imagen: '',
              Segments: [],
            },
            isNewFrec: true,
            isSession,
          }}
        >
          <FrecsTitle variant="bodylarge">+</FrecsTitle>
        </Link>
      </FrecsContainer>
    </Container>
  )
}

const Container = styled(AnimateChildren)`
  position: relative;
`

const FrecRemoveButton = styled(Button)`
  position: absolute;
  top: -20px;
  left: 95%;
  width: 60px;
  height: 60px;
`

const FrecRemoveImg = styled.img`
  filter: ${({ theme }) => theme.palette.filters.alternative};
  width: 19px;
  height: 19px;
`

const Title = styled(TextInput)`
  padding-bottom: 5px;
`

const Subtitle = styled(TextInput)`
  padding-bottom: 5px;
`

const Description = styled(TextInput)`
  padding-bottom: 5px;
`

const FrecsContainer = styled(Box)`
  flex-wrap: wrap;
  /* display: flex; */
`

const FrecsTitle = styled(Typography)`
  margin: 5px;

  cursor: pointer;
  transition: color 0.3s;

  &:hover {
    color: ${({ theme }) => theme.palette.alternative};
  }
`

export default ProductGroup
