export const palette = {
  brand: '#387ef5',
  brandLight: '#11c1f3',
  alternative: '#33eccb',

  filters: {
    alternative:
      'invert(90%) sepia(61%) saturate(5000%) hue-rotate(127deg) brightness(100%) contrast(85%)',
    black:
      'invert(0%) sepia(0%) saturate(17%) hue-rotate(324deg) brightness(95%) contrast(105%)',
    white:
      'invert(88%) sepia(100%) saturate(0%) hue-rotate(326deg) brightness(104%) contrast(103%)',
  },

  whites: [
    '#ffffff',
    '#E5E5E5',
    '#CCCCCC',
    '#B2B2B2',
    'rgba(255,255,255,.5)',
    'rgba(255,255,255,.1)',
  ],
  blacks: [
    '#000000',
    '#111111',
    '#222222',
    '#333333',
    'rgba(0,0,0,.5)',
    'rgba(0,0,0,.3)',
  ],
  blues: ['#1a52ab', '#3365b5', '#4d79be', '#668cc7', '#809fd1'],
  shades: ['#0c0912', '#331625', '#2c2246', '#53396f', '#5a56a7'],

  success: '#33cd5f',
  warning: '#ff5a00',
  error: '#ef473a',
  misc: '#886aea',
  dark: '#444444',

  transparent: 'transparent',
}
