import React from 'react'

import { ModalProvider } from './modal'
import { ToastProvider } from './toast'
import { DataProvider } from './data'
import { LanguageProvider } from './language'
import { UserProvider } from './user'
import { SimpleFrecsProvider } from './simplefrecs'

const ContextsProviders = ({ children }) => (
  <ModalProvider>
    <ToastProvider>
      <DataProvider>
        <SimpleFrecsProvider>
          <LanguageProvider>
            <UserProvider>{children}</UserProvider>
          </LanguageProvider>
        </SimpleFrecsProvider>
      </DataProvider>
    </ToastProvider>
  </ModalProvider>
)

export default ContextsProviders
