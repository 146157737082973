import React from 'react'
import { Link } from 'react-router-dom'

import styled from 'styled-components'

import Typography from '../typography'

const Tab = ({
  tab: { image, name, route, state },
  active,
  horizontal,
  isSimpleFrec,
  isGroup,
  onClick,
}) => {
  const Container = route ? Link : 'div'

  return (
    <Container to={route} state={state} {...{ onClick }}>
      <TabContainer>
        {horizontal ? (
          <TabActiveIndicator {...{ active, horizontal, isSimpleFrec }} />
        ) : null}

        <TabTitleContainer {...{ isSimpleFrec, isGroup }}>
          {image ? <TabIcon src={image} alt={name + '-tab-icon'} /> : null}
          {!horizontal && (
            <TabTitle
              variant={
                isSimpleFrec ? 'body' : isGroup ? 'bodylarge' : 'sectiontitle'
              }
            >
              {name}
            </TabTitle>
          )}
        </TabTitleContainer>
      </TabContainer>
    </Container>
  )
}

const TabContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  position: relative;
  transition: all 0.3s;

  :hover {
    background: ${({ theme }) => theme.palette.whites[5]};
    cursor: pointer;
    opacity: 0.9;
    box-shadow: 0px 15px 30px 0px #0000000d;
  }
`

const TabActiveIndicator = styled.div`
  display: ${({ horizontal }) => (horizontal ? 'block' : 'none')};
  position: absolute;
  top: ${({ horizontal }) => (horizontal ? '0' : 'auto')};
  left: ${({ horizontal }) => (!horizontal ? '0' : 'auto')};
  height: ${({ horizontal }) => (horizontal ? '5px' : '40px')};
  width: ${({ horizontal }) => (!horizontal ? '5px' : '40px')};
  transition: all 0.2s;
  background-color: ${({ active, theme }) =>
    active ? theme.palette.alternative : 'transparent'};
  border-radius: ${({ horizontal }) =>
    horizontal ? '0 0 20px 20px' : '0 20px 20px 0'};
`

const TabTitleContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: ${({ isSimpleFrec, isGroup }) =>
    isSimpleFrec ? '5px' : isGroup ? '10px' : '15px'};
  width: 100%;
`

const TabIcon = styled.img`
  background-color: ${({ theme }) => theme.palette.whites[0]};
  width: 50px;
  height: 50px;
  border-radius: 15px;
  margin: 10px;
`

const TabTitle = styled(Typography)`
  color: ${({ theme, active }) =>
    active ? theme.palette.alternative : theme.palette.whites[1]};
  padding-left: 10px;
  white-space: nowrap;
  overflow: hidden;
  display: block;
  text-overflow: ellipsis;
`

export default Tab
