import React, { createContext, useState, useEffect } from 'react'

import Toast from './components/toast'

export const ToastContext = createContext()
ToastContext.displayName = 'ToastContext'

export const ToastProvider = props => {
  const [toast, setToast] = useState(null)
  const value = [toast, setToast]

  useEffect(() => {
    if (toast) {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      })
    }
  }, [toast])

  return (
    <ToastContext.Provider value={value} {...props}>
      {toast ? (
        <Toast
          message={toast.message}
          duration={toast.duration}
          status={toast.status}
          onHide={() => setToast(null)}
        />
      ) : null}

      {props.children}
    </ToastContext.Provider>
  )
}

export const useToast = () => {
  const context = React.useContext(ToastContext)

  if (!context) {
    throw new Error('useToast must be used within a ToastProvider')
  }

  return context
}
