import styled from 'styled-components'

const ButtonText = styled.button`
  font-size: 15px;
  background-color: transparent;
  border: 1px solid
    ${({ theme, noBorder }) =>
      noBorder ? 'transparent' : theme.palette.alternative};
  border-radius: 5px;
  padding: 10px 20px;
  outline: none;
  cursor: pointer;
  transition: all 0.3s;

  &:hover {
    transform: ${({ hoverAnimation }) =>
      hoverAnimation ? 'translateY(-5px)' : 'none'};
  }

  &:active {
    background-color: ${({ theme, noBorder }) =>
      noBorder ? theme.palette.whites[4] : theme.palette.alternative};
  }
`

const Button = ({
  onClick,
  children,
  className,
  active = true,
  hoverAnimation = false,
  noBorder = false,
}) => {
  return (
    <ButtonText
      className={className}
      onClick={onClick}
      {...{ active, hoverAnimation, noBorder }}
    >
      {children}
    </ButtonText>
  )
}

export default Button
