import { createGlobalStyle } from 'styled-components'

import EncodeSansExpandedBlack from 'assets/fonts/EncodeSansExpanded-Black.ttf'
import EncodeSansExpandedBold from 'assets/fonts/EncodeSansExpanded-Bold.ttf'
import EncodeSansExpandedLight from 'assets/fonts/EncodeSansExpanded-Light.ttf'
import EncodeSansExpandedRegular from 'assets/fonts/EncodeSansExpanded-Regular.ttf'

const FontStyles = createGlobalStyle`
  @font-face {
    font-family: 'EncodeSansExpanded Black';
    src: url(${EncodeSansExpandedBlack}) format('truetype');
  }

  @font-face {
    font-family: 'EncodeSansExpanded Bold';
    src: url(${EncodeSansExpandedBold}) format('truetype');
  }

  @font-face {
    font-family: 'EncodeSansExpanded Light';
    src: url(${EncodeSansExpandedLight}) format('truetype');
  } 

  @font-face {
    font-family: 'EncodeSansExpanded Regular';
    src: url(${EncodeSansExpandedRegular}) format('truetype');
  } 

  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    list-style: none;
    text-decoration: none;
    border: none;
    outline: none;
  }

  body {
    background-color: #111;
  }


  /* width */
  ::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #555;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 10px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

`

export default FontStyles
