import React, { createContext } from 'react'
import { useLocalStorage } from 'hooks/useLocalStorage'

export const LanguageContext = createContext()
LanguageContext.displayName = 'LanguageContext'

export const LanguageProvider = props => {
  const [language, setLanguage] = useLocalStorage('@language', 'es')
  const value = [language, setLanguage]

  return <LanguageContext.Provider value={value} {...props} />
}

export const useLanguage = () => {
  const context = React.useContext(LanguageContext)

  if (!context) {
    throw new Error('useLanguage must be used within a LanguageProvider')
  }

  return context
}
