import styled from 'styled-components'

import SectionHeader from 'components/section-header'
import AnimateChildren from 'react-animate-children'

const Frequencies = () => {
  return (
    <Container speed={50}>
      <></>
    </Container>
  )
}

const Container = styled(AnimateChildren)``

export default Frequencies
