import React, { useState } from 'react'

import styled from 'styled-components'

import AnimateChildren from 'react-animate-children'

import Tab from './tab'

import Button from 'components/button'
import Typography from 'components/typography'

const Tabs = ({ tabs, horizontal, handleClickPress, isSession }) => {
  const [isHovered, setIsHovered] = useState(null)
  const [isHoveredGroupFrecs, setIsHoveredGroupFrecs] = useState(null)
  const [sort, setSort] = useState(true)

  return (
    <TabsContainer {...{ horizontal, isHovered }}>
      <TabsSubContainer speed={50} {...{ horizontal }}>
        {tabs.map((tab, index) => {
          if (tab.name === 'Divider') {
            return <Divider key={`tab-divider-${index}`} />
          }

          return (
            <TabContainer
              key={`tab-${tab.route}-${index}`}
              active={
                location.pathname.split('/')[
                  location.pathname.split('/').length - 1
                ] === tab.route.split('/')[tab.route.split('/').length - 1] ||
                tab.code === location.pathname.split('/')[2]
              }
            >
              <Tab
                {...{ tab, horizontal }}
                onClick={() => {
                  isHovered === tab.name
                    ? setIsHovered(null)
                    : setIsHovered(tab.name)
                }}
                active={
                  location.pathname.split('/')[
                    location.pathname.split('/').length - 1
                  ] === tab.route.split('/')[tab.route.split('/').length - 1] ||
                  tab.code === location.pathname.split('/')[2]
                }
              />

              {tab.frecs && isHovered === tab.name ? (
                <FrecsContainer
                  className={isHovered ? 'show' : 'hide'}
                  {...{ isHovered }}
                >
                  <FrecsSortButton
                    hoverAnimation
                    noBorder
                    onClick={() => {
                      setSort(sort => !sort)
                    }}
                  >
                    <FrecsSortButtonText variant="bodylarge">
                      ⇅ Ordenar
                    </FrecsSortButtonText>
                  </FrecsSortButton>

                  {(sort ? tab.frecs : [...tab.frecs].reverse())?.map(frec => (
                    <Tab
                      onClick={() => {
                        handleClickPress(
                          [
                            {
                              frec: frec.hz,
                              Duracion: frec.hz === '0' ? 3 : 90,
                              Canal: 0,
                              amp: 32000,
                              ref: frec.Nombre,
                            },
                          ],
                          {
                            Nombre: frec.Nombre || frec.hz,
                            Summary: ' ',
                          },
                        )
                        window.scrollTo({ top: 0, behavior: 'smooth' })
                      }}
                      key={`simple-frec-${frec.hz}-${frec.Nombre}`}
                      isSimpleFrec
                      tab={{
                        name: `${frec.hz} ${frec.Nombre}`,
                        route: '',
                      }}
                    />
                  ))}
                </FrecsContainer>
              ) : null}

              {tab.groups && isHovered === tab.name ? (
                <FrecsContainer
                  className={isHovered ? 'show' : 'hide'}
                  {...{ isHovered }}
                >
                  {tab.groups?.map((group, index) => (
                    <div
                      key={`simple-frec-${group.hz}-${group.Nombre}-${index}`}
                    >
                      <Tab
                        tab={{
                          name: group.Nombre,
                          route: '',
                        }}
                        isGroup
                        onClick={async () => {
                          if (tab.name === 'Sesiones' && !isSession) {
                            handleClickPress(
                              await Promise.all(
                                group.frecs.map(async frec => {
                                  return frec.Segments.map(segment => ({
                                    frec: segment.frec,
                                    Duracion: segment.Duracion,
                                    Canal: 0,
                                    amp: 32000,
                                    ref: '',
                                    subgroup: `${group.Nombre} - ${frec.Nombre}`,
                                    session: group,
                                  }))
                                }),
                              ),
                              null,
                              true,
                            )
                            return
                          }

                          isHoveredGroupFrecs === group.Nombre
                            ? setIsHoveredGroupFrecs(null)
                            : setIsHoveredGroupFrecs(group.Nombre)
                        }}
                      />

                      <FrecsContainer
                        className={isHoveredGroupFrecs ? 'show' : 'hide'}
                        {...{ isHoveredGroupFrecs }}
                      >
                        {group.Nombre === isHoveredGroupFrecs &&
                          group.frecs?.map((frec, index) => (
                            <>
                              <Tab
                                onClick={() => {
                                  handleClickPress(
                                    frec.Segments.map(segment => ({
                                      frec: segment.frec,
                                      Duracion: segment.Duracion,
                                      Canal: 0,
                                      amp: 32000,
                                      ref: '',
                                      subgroup: `${
                                        tab.name === 'Sesiones'
                                          ? tab.name.slice(0, -2)
                                          : tab.name.slice(0, -1)
                                      } ${frec.Nombre}`,
                                    })),
                                    frec,
                                  )

                                  window.scrollTo({
                                    top: 0,
                                    behavior: 'smooth',
                                  })
                                }}
                                key={`simple-frec-${frec.hz}-${frec.Nombre}`}
                                isSimpleFrec
                                tab={{
                                  name: frec.Nombre,
                                  route: '',
                                }}
                              />

                              {index === group.frecs.length - 1 ? (
                                <div style={{ height: 20 }} />
                              ) : null}
                            </>
                          ))}
                      </FrecsContainer>
                    </div>
                  ))}
                </FrecsContainer>
              ) : null}
            </TabContainer>
          )
        })}
      </TabsSubContainer>
    </TabsContainer>
  )
}

const TabsContainer = styled.div`
  background-color: ${({ theme }) => theme.palette.whites[5]};
  border-radius: 20px;
  margin: ${({ horizontal }) => (horizontal ? '20px' : '0 20px 0 0')};
  box-shadow: 0px 15px 30px 0px #0000000d;
  width: ${({ horizontal }) => (horizontal ? 'fit-content' : '300px')};
  display: ${({ horizontal }) => (horizontal ? 'flex' : 'block')};
`

const TabsSubContainer = styled(AnimateChildren)`
  display: ${({ horizontal }) => (horizontal ? 'flex' : 'block')};
`

const Divider = styled.div`
  margin: 10px 0;
  width: 100%;
  height: 2px;
  background-color: ${({ theme }) => theme.palette.alternative};
`

const TabContainer = styled.div``

const FrecsContainer = styled.div`
  position: relative;
  height: ${({ isHovered }) => (isHovered ? 'auto' : 'fit-content')};
  padding: 0 5px;

  &.hide {
    position: absolute;
    animation: hide 0.3s;
    animation-fill-mode: forwards;
    @keyframes hide {
      from {
        transform: translateX(0);
      }
      to {
        transform: translateX(-200%);
      }
    }
  }
  &.show {
    position: relative;
    animation: show 0.3s;
    animation-fill-mode: forwards;
    @keyframes show {
      from {
        transform: translateX(-200%);
      }
      to {
        transform: translateX(0);
      }
    }
  }
`

const FrecsSortButton = styled(Button)`
  border-radius: 20px;
`

const FrecsSortButtonText = styled(Typography)`
  color: ${({ theme }) => theme.palette.alternative};
`

export default Tabs
