import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

import { useModal } from '../index'

import Typography from 'components/typography'

const Modal = ({ children, onClose }) => {
  const [, setModal] = useModal()

  const [toastVisibility, setModalVisibility] = useState(false)

  useEffect(() => {
    setModalVisibility(true)
  }, [])

  const handleCloseModal = () => {
    setModalVisibility(false)

    setTimeout(() => {
      onClose()
    }, 1000)
  }

  return (
    <Container>
      <Close>
        <Typography variant="subtitle" onClick={() => handleCloseModal}>
          x
        </Typography>
      </Close>

      <div onClick={() => alert('asd')}>
        <h1>title</h1>
      </div>

      <SubContainer visible={toastVisibility}>{children}</SubContainer>
    </Container>
  )
}

const Container = styled.div`
  position: absolute;
  z-index: 1;
  display: flex;
  width: 100vw;
  height: 100vw;
  background-color: white;
`

const SubContainer = styled.div`
  border-radius: 5px;
  padding: 10px 20px;
  margin: 20px 0;

  transition: opacity 0.3s ease-in-out;
  opacity: ${({ visible }) => (visible ? 1 : 0)};
`

const Close = styled.div`
  position: absolute;
  top: 0;
  right: 20px;
  z-index: 3;
`

export default Modal
