import React, { createContext, useState } from 'react'

import Modal from './components/modal'

export const ModalContext = createContext()
ModalContext.displayName = 'ModalContext'

export const ModalProvider = props => {
  const [modal, setModal] = useState(null)
  const value = [modal, setModal]

  return (
    <ModalContext.Provider value={value} {...props}>
      {modal ? <Modal onClose={() => setModal(false)}>{modal}</Modal> : null}

      {props.children}
    </ModalContext.Provider>
  )
}

export const useModal = () => {
  const context = React.useContext(ModalContext)

  if (!context) {
    throw new Error('useModal must be used within a ModalProvider')
  }

  return context
}
