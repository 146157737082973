import Tabs from 'components/tabs'
import Typography from 'components/typography'
import styled from 'styled-components'

import { useLanguage } from 'contexts/language'

import biofoton from 'assets/biofoton.png'
import galaxfamily from 'assets/galaxfamily.png'
import lightbeauty from 'assets/lightbeauty.png'
import odonlight from 'assets/odonlight.png'
import expert from 'assets/expert.png'
import config from 'assets/icon/config.png'

const Header = ({ user }) => {
  const tabs = [
    {
      image: biofoton,
      name: 'Biofoton 2',
      code: 'bf',
      route: '/products/bf',
    },
    {
      image: galaxfamily,
      name: 'Galax Family',
      code: 'gf',
      route: '/products/gf',
    },
    {
      image: lightbeauty,
      name: 'Light Beauty',
      code: 'lb',
      route: '/products/lb',
    },
    {
      image: odonlight,
      name: 'Odon Light',
      code: 'ol',
      route: '/products/ol',
    },
    {
      image: expert,
      name: 'Sistema Experto',
      code: 'se',
      route: '/products/se',
    },
    {
      image: config,
      name: 'Config',
      code: 'cf',
      route: '/config',
    },
  ]

  const [language, setLanguage] = useLanguage()

  const handleLanguageChange = checked => {
    /**
     * * checked: true = en
     * * checked: false = es
     */
    const newLanguage = checked ? 'en' : 'es'

    setLanguage(newLanguage)
  }

  return (
    <Container>
      <SubContainer>
        <Title variant="title">Biopanel</Title>

        {user ? <Tabs {...{ tabs }} horizontal /> : null}

        <SwitchContainer onChange={e => handleLanguageChange(e.target.checked)}>
          <SwitchInput
            type="checkbox"
            defaultChecked={language === 'en' ? true : false}
          />
          <SwitchSlider className="slider round" />
        </SwitchContainer>
      </SubContainer>

      <Divider />
    </Container>
  )
}

const Container = styled.div`
  width: 100%;
`

const SubContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
`

const Title = styled(Typography)`
  padding: 30px 0;
  color: ${({ theme }) => theme.palette.whites[4]};
  width: 90px;

  @media screen and (max-width: 468px) {
    font-size: 25px;
  }
`

const SwitchContainer = styled.label`
  position: relative;
  display: inline-block;
  width: 90px;
  height: 34px;

  input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: ${({ theme }) => theme.palette.brandLight};
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  .slider:before {
    position: absolute;
    content: '';
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  input:checked + .slider {
    background-color: ${({ theme }) => theme.palette.brand};
  }

  input:focus + .slider {
    box-shadow: 0 0 1px ${({ theme }) => theme.palette.brand};
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(56px);
    -ms-transform: translateX(56px);
    transform: translateX(56px);
  }

  .slider.round {
    border-radius: 34px;
  }

  .slider.round:before {
    border-radius: 50%;
  }

  .slider:after {
    content: 'ES';
    color: white;
    display: block;
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    font-size: 15px;
    font-family: Verdana, sans-serif;
  }

  input:checked + .slider:after {
    content: 'EN';
  }
`

const SwitchInput = styled.input``

const SwitchSlider = styled.span``

const Divider = styled.div`
  width: 100%;
  height: 2px;
  background-color: ${({ theme }) => theme.palette.whites[4]};
`

export default Header
