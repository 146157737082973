import React, { createContext } from 'react'
import { useLocalStorage } from 'hooks/useLocalStorage'

export const DataContext = createContext()
DataContext.displayName = 'DataContext'

export const DataProvider = props => {
  const [data, setData] = useLocalStorage('@data', null)
  const value = [data, setData]

  return <DataContext.Provider value={value} {...props} />
}

export const useData = () => {
  const context = React.useContext(DataContext)

  if (!context) {
    throw new Error('useData must be used within a DataProvider')
  }

  return context
}
