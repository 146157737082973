import { useEffect, useState } from 'react'

import styled from 'styled-components'

import Typography from 'components/typography'

const TextInput = ({
  variant,
  children,
  className,
  fontSize,
  dataKey,
  onFinish,
  onChange,
  password,
  defaultEditing,
  error,
  value,
  disableEditing,
  width,
  options,
}) => {
  const [isEditing, setIsEditing] = useState(defaultEditing)

  useEffect(() => {
    if (disableEditing) {
      setIsEditing(false)
    }
  }, [disableEditing])

  const [text, setText] = useState(password ? '' : children || value)

  useEffect(() => {
    setText(password ? '' : children || value)
  }, [children])

  const handleKeyDown = event => {
    if (event.key === 'Enter') {
      onFinish(text, dataKey)
      setIsEditing(false)
    }

    if (event.key === 'Escape') {
      setIsEditing(false)
    }
  }

  if (!isEditing) {
    return (
      <Text
        {...{ variant, children, className, disableEditing }}
        onClick={() => !disableEditing && setIsEditing(true)}
      />
    )
  }

  const Input = password ? InputSmall : options ? InputSelect : InputLarge

  return (
    <>
      <Input
        onFocus={e =>
          !options
            ? e.currentTarget.setSelectionRange(
                e.currentTarget.value.length,
                e.currentTarget.value.length,
              )
            : null
        }
        {...{ fontSize, error, width }}
        autoFocus
        type={password ? 'password' : 'text'}
        value={options ? value : text}
        rows={Math.floor(text?.length / 50) || 1}
        onKeyDown={handleKeyDown}
        onChange={e => {
          setText(e.target.value)

          if (onChange) {
            onChange(e.target.value)
          }

          if (options) {
            onFinish(e.target.value, dataKey)
            setIsEditing(false)
          }
        }}
      >
        {options ? (
          <>
            {options.map(({ value, name }) => (
              <option key={`${value}-${name}`} value={value}>
                {name}
              </option>
            ))}
          </>
        ) : null}
      </Input>
    </>
  )
}

const Text = styled(Typography)`
  cursor: ${({ disableEditing }) => (disableEditing ? '' : 'pointer')};
  transition: color 0.3s;

  &:hover {
    color: ${({ theme, disableEditing }) =>
      !disableEditing && theme.palette.alternative};
  }
`

const InputLarge = styled.textarea`
  resize: none;
  width: ${({ width }) => width || '100%'};
  background: transparent;
  padding-bottom: 5px;
  margin-bottom: 5px 0;
  color: ${({ theme, error }) =>
    error ? theme.palette.error : theme.palette.alternative};
  border-bottom: 1px solid
    ${({ theme, error }) =>
      error ? theme.palette.error : theme.palette.alternative};
  font-size: ${({ fontSize }) => `${fontSize}px`};
  font-family: EncodeSansExpanded Bold;
`

const InputSmall = styled.input`
  width: 100%;
  background: transparent;
  padding: 10px 20px;
  margin: 10px 0;
  color: ${({ theme }) => theme.palette.alternative};
  border-bottom: 1px solid ${({ theme }) => theme.palette.alternative};
  font-size: ${({ fontSize }) => `${fontSize}px`};
  font-family: EncodeSansExpanded Bold;
`

const InputSelect = styled.select`
  width: 100%;
  background: transparent;
  color: ${({ theme }) => theme.palette.alternative};
  border-bottom: 1px solid ${({ theme }) => theme.palette.alternative};
  font-size: ${({ fontSize }) => `${fontSize}px`};
  font-family: EncodeSansExpanded Bold;
`

export default TextInput
