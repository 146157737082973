import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

import Typography from 'components/typography'

const Toast = ({ message, duration = 5000, status, onHide }) => {
  const [toastVisibility, setToastVisibility] = useState(false)

  useEffect(() => {
    setTimeout(() => {
      setToastVisibility(true)

      setTimeout(() => {
        setToastVisibility(false)

        setTimeout(() => {
          onHide()
        }, 1000)
      }, duration)
    }, 1)
  }, [])

  return (
    <Container visible={toastVisibility}>
      <SubContainer visible={toastVisibility} {...{ status }}>
        <Text variant="bodylarge">{message}</Text>
      </SubContainer>
    </Container>
  )
}

const Container = styled.div`
  position: absolute;
  transition: top 0.5s ease-in-out;
  top: ${({ visible }) => (visible ? 0 : '-100%')};
  z-index: 1;
  display: flex;
  width: fit-content;
  left: 0;
  right: 0;
  margin: auto;
  justify-content: center;
  align-items: center;
`

const SubContainer = styled.div`
  background-color: ${({ theme, status }) => theme.palette[status]};
  box-shadow: 0px 15px 30px 0px #0000000d;
  border-radius: 5px;
  padding: 10px 20px;
  margin: 20px 0;

  @media screen and (max-width: 768px) {
    width: 90vw;
  }
`

const Text = styled(Typography)`
  color: ${({ theme }) => theme.palette.white};
`

export default Toast
