import React, { createContext } from 'react'
import { useLocalStorage } from 'hooks/useLocalStorage'

import simpleFrecs from 'data/fs.json'

export const SimpleFrecsContext = createContext()
SimpleFrecsContext.displayName = 'SimpleFrecsContext'

export const SimpleFrecsProvider = props => {
  const [simplefrecs, setSimpleFrecs] = useLocalStorage(
    '@simplefrecs',
    simpleFrecs,
  )
  const value = [simplefrecs, setSimpleFrecs]

  return <SimpleFrecsContext.Provider value={value} {...props} />
}

export const useSimpleFrecs = () => {
  const context = React.useContext(SimpleFrecsContext)

  if (!context) {
    throw new Error('useSimpleFrecs must be used within a SimpleFrecsProvider')
  }

  return context
}
