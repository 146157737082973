import { useEffect, useState } from 'react'

import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'

import styled from 'styled-components'

import { getFrecsByProduct, postFrecByProduct, removeFrecByProduct } from 'api'
import { useSimpleFrecs } from 'contexts/simplefrecs'
import { useLanguage } from 'contexts/language'
import { useData } from 'contexts/data'

import Box from 'components/box'
import AnimateChildren from 'react-animate-children'
import TextInput from 'components/textinput'
import Typography from 'components/typography'
import Button from 'components/button'
import Tabs from 'components/tabs'

import deleteIcon from 'assets/icon/delete.png'

const ProductFrec = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }, [])

  const [language] = useLanguage()
  const [data, setData] = useData()
  const [simpleFrecs, setSimpleFrecs] = useSimpleFrecs()

  const { product, frec_id } = useParams()

  const { state: locationState } = useLocation()
  const navigate = useNavigate()

  const [state, setState] = useState({
    ...locationState,
  })

  useEffect(() => {
    navigate('.', {
      replace: true,
      state: {
        ...locationState,
      },
    })

    setState({ ...locationState })
  }, [frec_id])

  let { group, frec, section, isNewFrec, isSimpleFrec, isSession } = state

  const [isEditing, setIsEditing] = useState(isNewFrec)

  useEffect(() => {
    setTimeout(() => {
      setIsEditing(true)
    }, 500)
  }, [])

  const tabs = [
    {
      name: 'Programas',
      route: '',
      groups: data?.bf?.getgroups?.programs[language]
        .flat()
        .map((group, groupIndex) => {
          return {
            ...group,
            frecs: data?.bf?.getfrecsbygroup?.programs[language][groupIndex],
          }
        }),
    },
    {
      name: 'Frecuencias',
      route: '',
      groups: data?.bf?.getgroups?.frecuencies[language]
        .flat()
        .map((group, groupIndex) => {
          return {
            ...group,
            frecs: data?.bf?.getfrecsbygroup?.frecuencies[language][groupIndex],
          }
        }),
    },
    {
      name: 'Sesiones',
      route: '',
      groups: data?.bf?.getsessions[language]
        .flat()
        .map((group, groupIndex) => {
          return {
            ...group,
            frecs: data?.bf?.getfrecsbysession[language][groupIndex],
          }
        }),
    },
    {
      name: 'Frecuencias Simples',
      route: '',
      frecs: simpleFrecs?.frecs[language],
    },
  ]

  const frecsByProduct = getFrecsByProduct(product, section)

  const updateLocalState = newState => {
    setState({
      ...state,
      ...newState,
    })

    navigate('.', {
      replace: true,
      state: {
        ...state,
        ...newState,
      },
    })
  }

  useEffect(() => {
    return
    if (!isNewFrec) {
      if (!group) {
        const newFrec =
          data[product][frecsByProduct[0]][language][frec.frecIndex]

        newFrec &&
          updateLocalState({
            frec: newFrec,
          })
      }

      if (group) {
        if (frecsByProduct[1]) {
          const newFrec =
            data[product][frecsByProduct[0]][frecsByProduct[1]][language][
              group.groupFrecsIndex
            ][frec.frecIndex]

          console.log(newFrec)

          newFrec &&
            updateLocalState({
              frec: newFrec,
            })
        }

        if (!frecsByProduct[1]) {
          const newFrec =
            data[product][frecsByProduct[0]][language][group.groupFrecsIndex][
              frec.frecIndex
            ]

          newFrec &&
            updateLocalState({
              frec: newFrec,
            })
        }
      }
    }
  }, [])

  const getSegmentsRefs = () => {
    if (frec?.Segments) {
      const newFrec = {
        ...frec,
        Segments: frec.Segments.map((segment, index) => {
          const reference = (simpleFrecs?.frecs[language] || []).find(
            ({ hz }) => {
              return (
                Number(hz.replaceAll(',', '.')) ===
                  Number(segment.frec.replaceAll(',', '.')) ||
                hz === segment.frec
              )
            },
          )?.Nombre

          return {
            ...segment,
            ref: reference,
          }
        }),
      }

      updateLocalState({
        frec: newFrec,
      })
    }
  }

  useEffect(() => {
    getSegmentsRefs()
  }, [])

  const handleFrecChange = async (
    newFrecValue,
    dataKey,
    multipleValuesAndKeys,
    callback,
  ) => {
    const newSecs = await frec?.Segments?.reduce(
      (acc, segment) => acc + +segment.Duracion,
      0,
    )

    let newFrec = {
      ...frec,
    }

    if (multipleValuesAndKeys) {
      const newFrecValues = multipleValuesAndKeys.reduce(
        (acc, [value, key]) => {
          acc[key] = value
          return acc
        },
        {},
      )
      newFrec = {
        ...frec,
        Segundos: newSecs,
        ...newFrecValues,
      }
    } else {
      newFrec = {
        ...frec,
        Segundos: newSecs,
        [dataKey]: newFrecValue,
      }
    }

    setState({
      ...state,
      frec: newFrec,
    })

    const newData =
      product === 'fs'
        ? postFrecByProduct(
            {
              fs: simpleFrecs,
            },
            product,
            frecsByProduct,
            language,
            group,
            frec,
            newFrec,
          )
        : postFrecByProduct(
            data,
            product,
            frecsByProduct,
            language,
            group,
            frec,
            newFrec,
          )

    product === 'fs' ? setSimpleFrecs(newData.fs) : setData(newData)

    navigate('.', {
      replace: true,
      state: {
        ...state,
        frec: newFrec,
      },
    })

    if (callback) {
      callback({
        ...state,
        frec: newFrec,
      })
    }
  }

  const handleSegmentChange = (newFrecValue, dataKey, segmentsIndex) => {
    const newSegments = [...frec.Segments]

    newSegments[segmentsIndex][dataKey] = newFrecValue.toString()

    const newFrec = {
      ...frec,
      Segments: newSegments,
    }

    setState({
      ...state,
      frec: newFrec,
    })

    const newData = postFrecByProduct(
      data,
      product,
      frecsByProduct,
      language,
      group,
      frec,
      newFrec,
    )

    setData(newData)

    navigate('.', {
      replace: true,
      state: {
        ...state,
        frec: newFrec,
      },
    })
  }

  const [isRemoving, setIsRemoving] = useState(false)

  const handleSegmentRemove = segmentsIndex => {
    setIsRemoving(segmentsIndex)

    setIsRemoving(null)

    const newSegments = [...frec.Segments]

    newSegments.splice(segmentsIndex, 1)

    const newFrec = {
      ...frec,
      Segments: newSegments,
    }

    setState({
      ...state,
      frec: newFrec,
    })

    const newData = postFrecByProduct(
      data,
      product,
      frecsByProduct,
      language,
      group,
      frec,
      newFrec,
    )

    setData(newData)

    navigate('.', {
      replace: true,
      state: {
        ...state,
        frec: newFrec,
      },
    })
  }

  const handleAddSegment = (newSegments, replace, newState) => {
    const { frec } = newState || state
    const oldSegments = replace ? [] : [...frec.Segments]

    const mergedSegments = [...oldSegments, ...newSegments].map(segment => {
      const reference = (simpleFrecs?.frecs[language] || []).find(({ hz }) => {
        return (
          Number(hz.replaceAll(',', '.')) ===
            Number(segment.frec.replaceAll(',', '.')) || hz === segment.frec
        )
      })?.Nombre

      return {
        ...segment,
        ref: reference,
      }
    })

    const newSecs = mergedSegments?.reduce(
      (acc, segment) => acc + +segment.Duracion,
      0,
    )

    const newFrec = {
      ...frec,
      Segundos: newSecs,
      Segments: mergedSegments,
    }

    updateLocalState({
      frec: newFrec,
    })

    const newData = postFrecByProduct(
      data,
      product,
      frecsByProduct,
      language,
      group,
      frec,
      newFrec,
    )

    setData(newData)

    navigate('.', {
      replace: true,
      state: {
        ...state,
        frec: newFrec,
      },
    })
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }

  const handleRemoveFrec = () => {
    if (
      confirm(
        `Estás seguro que quieres eliminar ${frec?.Nombre ||
          `esta frecuencia`}?`,
      )
    ) {
      const newData = removeFrecByProduct(
        data,
        product,
        frecsByProduct,
        language,
        group,
        frec,
      )

      if (!group) {
        setData(newData)
        navigate(`/products/${product}`, {
          replace: true,
          state: {
            frecs: newData,
            section,
            isSession,
          },
        })

        return
      }

      const newGroup = frecsByProduct[1]
        ? newData[product][frecsByProduct[0]][frecsByProduct[1]][language][
            group.groupFrecsIndex
          ]
        : newData[product][frecsByProduct[0]][language][group.groupFrecsIndex]

      setData(newData)

      navigate(`/products/${product}/group/${group.groupFrecsIndex}`, {
        replace: true,
        state: {
          group: {
            ...group,
            frequencies: product === 'lb' ? newGroup.frequencies : newGroup,
          },
          frecs: product === 'lb' ? newGroup.frequencies : newGroup,
          section,
          isSession,
        },
      })
    }
  }

  const handleReorderFrecSegments = newSegments => {
    const newFrec = {
      ...frec,
      Segments: newSegments,
    }

    setState({
      ...state,
      frec: {
        ...state.frec,
        Segments: newSegments,
      },
    })

    const newData = postFrecByProduct(
      data,
      product,
      frecsByProduct,
      language,
      group,
      frec,
      newFrec,
    )

    setData(newData)

    navigate('.', {
      replace: true,
      state: {
        ...state,
        frec: newFrec,
      },
    })
  }

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list)
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)

    return result
  }

  function onDragEnd(result) {
    if (!result.destination) {
      return
    }

    const newSegments = reorder(
      frec?.Segments,
      result.source.index,
      result.destination.index,
    )

    handleReorderFrecSegments(newSegments)
  }

  const getItemStyle = (_, draggableStyle) => ({
    ...draggableStyle,
    height: '35px',
  })

  const getmode = mode => {
    switch (mode) {
      case 'Led + Laser':
        return 0
      case 'Laser':
        return 1
      case 'Led':
        return 2
      case 'Aux':
        return 3
      case 'Aux + Led':
        return 6
      case 'Aux + Laser':
        return 7

      default:
        break
    }
  }

  const getModeFromNumber = modenumber => {
    switch (modenumber) {
      case 0:
        return 'Led + Laser'
      case 1:
        return 'Laser'
      case 2:
        return 'Led'
      case 3:
        return 'Aux'
      case 6:
        return 'Aux + Led'
      case 7:
        return 'Aux + Laser'

      default:
        break
    }
  }

  const isExpert = product === 'se'

  return (
    <Container speed={50}>
      <TabsContainer>
        <Tabs
          {...{ tabs, isSession }}
          handleClickPress={(newSegments, parentFrec, isAddingWholeSession) => {
            // isSession => the frec being edited is a session
            // isAddingWholeSession => the frec being added is a whole session
            if (isSession) {
              handleFrecChange(
                null,
                null,
                [
                  [parentFrec.Nombre, 'Nombre'],
                  [parentFrec.Summary, 'Descripcion'],
                ],
                newState => {
                  handleAddSegment(
                    newSegments.map(segment => segment),
                    true,
                    newState,
                  )
                },
              )
            } else if (isAddingWholeSession) {
              if (isExpert && newSegments[0][0].session) {
                setIsEditing(false)
                handleFrecChange(
                  null,
                  null,
                  [
                    [newSegments[0][0].session.Nombre, 'Nombre'],
                    [newSegments[0][0].session.Observaciones, 'Descripcion'],
                    [6, 'Mode'],
                  ],
                  newState => {
                    handleAddSegment(
                      newSegments.map(segment => segment[0]),
                      false,
                      newState,
                    )

                    setTimeout(() => {
                      setIsEditing(true)
                    }, 1000)
                  },
                )
              } else handleAddSegment(newSegments.map(segment => segment[0]))
            } else {
              if (isExpert && parentFrec) {
                setIsEditing(false)
                handleFrecChange(
                  null,
                  null,
                  [
                    [parentFrec.Nombre, 'Nombre'],
                    [parentFrec.Summary, 'Descripcion'],
                    [parentFrec.Mode, 'Mode'],
                  ],
                  newState => {
                    handleAddSegment(
                      newSegments.map(segment => segment),
                      false,
                      newState,
                    )

                    setTimeout(() => {
                      setIsEditing(true)
                    }, 1000)
                  },
                )
              } else handleAddSegment(newSegments.map(segment => segment))
            }
          }}
        />
      </TabsContainer>

      <Subcontainer>
        {!isSimpleFrec ? (
          <FrecRemoveButton
            onClick={() => handleRemoveFrec()}
            noBorder
            hoverAnimation
            float
          >
            <FrecRemoveImg src={deleteIcon} alt="delete-frec" />
          </FrecRemoveButton>
        ) : null}

        <Title
          variant="bodylarge"
          dataKey="Nombre"
          onFinish={(value, dataKey) => handleFrecChange(value, dataKey)}
          fontSize={16}
          defaultEditing={isNewFrec && !isSession}
          disableEditing={isSimpleFrec || !isEditing}
        >
          {frec?.Nombre || 'Nombre'}
        </Title>

        {console.log(frec)}

        <Subtitle
          variant="bodylarge"
          dataKey={
            typeof frec?.Summary === 'string'
              ? 'Summary'
              : typeof frec?.Descripcion === 'string'
              ? 'Descripcion'
              : 'DescripcionAmpliada'
          }
          onFinish={(value, dataKey) => handleFrecChange(value, dataKey)}
          fontSize={16}
          defaultEditing={isNewFrec && !isSession}
          disableEditing={isSimpleFrec || !isEditing}
        >
          {frec?.Summary || frec?.Descripcion || 'Descripcion'}
        </Subtitle>

        {frec?.Mode ? (
          <>
            <Typography variant="bodylarge">Modo: </Typography>
            <Description
              variant="bodylarge"
              dataKey="Mode"
              onFinish={(value, dataKey) => handleFrecChange(value, dataKey)}
              fontSize={16}
              defaultEditing={isNewFrec && !isSession}
              disableEditing={isSimpleFrec || !isEditing}
              value={frec?.Mode}
              options={[
                { value: 0, name: 'Led + Laser' },
                { value: 1, name: 'Laser' },
                { value: 2, name: 'Led' },
                { value: 3, name: 'Aux' },
                { value: 6, name: 'Aux + Led' },
                { value: 7, name: 'Aux + Laser' },
              ]}
            >
              {getModeFromNumber(Number(frec?.Mode))}
            </Description>
          </>
        ) : null}

        {frec?.Imagen ? (
          <>
            <Typography variant="bodylarge">Imagen:</Typography>
            <Description
              variant="bodylarge"
              dataKey="Imagen"
              onFinish={(value, dataKey) => handleFrecChange(value, dataKey)}
              fontSize={16}
              defaultEditing={isNewFrec && !isSession}
            >
              {frec?.Imagen || '_'}
            </Description>
          </>
        ) : null}

        <>
          <Typography variant="bodylarge">Segundos:</Typography>
          <Typography variant="bodylarge">
            {frec?.Segments?.reduce((acc, segment) => {
              return acc + +segment.Duracion
            }, 0)}
          </Typography>
        </>

        {frec?.Segments ? (
          <>
            <FrecsContainer p={0} m={0}>
              <IndicatorsContainer>
                {[
                  'HZ',
                  'Referencia',
                  'Subgrupo',
                  'Duracion',
                  'Canal',
                  'Amplitud',
                ].map((value, index) => (
                  <FrecSubContainer
                    key={`section-group-${value}-segments-column`}
                    width={index === 1 || index === 2 ? '22.5%' : '10%'}
                  >
                    <Typography variant="bodylargebold">{value}</Typography>
                  </FrecSubContainer>
                ))}
                <div
                  style={{
                    width: '2.2%',
                  }}
                ></div>
              </IndicatorsContainer>

              <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="droppable">
                  {provided => (
                    <div {...provided.droppableProps} ref={provided.innerRef}>
                      {frec?.Segments?.map(
                        (
                          {
                            Canal,
                            frec: hz,
                            amp,
                            Duracion,
                            ref = '',
                            subgroup = '',
                          },
                          segmentsIndex,
                        ) => {
                          return (
                            <Draggable
                              key={`${segmentsIndex}-${hz}-${Duracion}`}
                              draggableId={`${segmentsIndex}-${hz}-${Duracion}`}
                              index={segmentsIndex}
                            >
                              {(provided, snapshot) => (
                                <div
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  style={getItemStyle(
                                    snapshot.isDragging,
                                    provided.draggableProps.style,
                                  )}
                                >
                                  <FrecContainer
                                    p={0}
                                    m={0}
                                    key={`section-group-${frec?.Nombre}-segments-${hz}-${Duracion}-${segmentsIndex}`}
                                    className={
                                      isRemoving === segmentsIndex
                                        ? 'hidden'
                                        : ''
                                    }
                                  >
                                    {[
                                      hz,
                                      ref,
                                      subgroup,
                                      Duracion,
                                      Canal,
                                      amp,
                                    ].map((value, index) => (
                                      <FrecSubContainer
                                        key={`section-group-${frec?.Nombre}-segments-${hz}-${Duracion}-${index}`}
                                        width={
                                          index === 1 || index === 2
                                            ? '22.5%'
                                            : '10%'
                                        }
                                      >
                                        <FrecText
                                          variant="bodylarge"
                                          width="45%"
                                          disableEditing={
                                            [
                                              'frec',
                                              'ref',
                                              'subgroup',
                                              'Duracion',
                                              'Canal',
                                              'amp',
                                            ][index] === 'ref' ||
                                            [
                                              'frec',
                                              'ref',
                                              'subgroup',
                                              'Duracion',
                                              'Canal',
                                              'amp',
                                            ][index] === 'subgroup'
                                          }
                                          dataKey={
                                            [
                                              'frec',
                                              'ref',
                                              'subgroup',
                                              'Duracion',
                                              'Canal',
                                              'amp',
                                            ][index]
                                          }
                                          type="number"
                                          onFinish={(value, dataKey) =>
                                            handleSegmentChange(
                                              value,
                                              dataKey,
                                              segmentsIndex,
                                            )
                                          }
                                          fontSize={14}
                                        >
                                          {value}
                                        </FrecText>
                                      </FrecSubContainer>
                                    ))}

                                    <FrecRemoveButton
                                      noBorder
                                      hoverAnimation
                                      onClick={() =>
                                        handleSegmentRemove(segmentsIndex)
                                      }
                                    >
                                      <FrecRemoveImg
                                        src={deleteIcon}
                                        alt="delete-frec"
                                      />
                                    </FrecRemoveButton>
                                  </FrecContainer>
                                </div>
                              )}
                            </Draggable>
                          )
                        },
                      )}

                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            </FrecsContainer>
          </>
        ) : null}
      </Subcontainer>
    </Container>
  )
}

const Container = styled(AnimateChildren)`
  position: relative;
  display: flex;
  width: 100%;

  * > {
    &.hidden {
      animation: hide 1s ease;
      animation-fill-mode: forwards;
    }

    &.show {
      animation: show 1s ease;
    }
  }

  > * {
    &:first-child {
      width: 23%;
    }

    &:nth-child(2) {
      width: 77%;
    }
  }
`

const TabsContainer = styled.div``

const Subcontainer = styled.div`
  width: 100%;
  > * {
    width: 100%;
  }
  &:first-child {
    width: 100%;
  }
`

const Title = styled(TextInput)`
  padding-bottom: 5px;
`

const Subtitle = styled(TextInput)`
  padding-bottom: 5px;
`

const Description = styled(TextInput)`
  padding-bottom: 5px;
`

const FrecsContainer = styled.div`
  margin-top: 10px;
`

const FrecContainer = styled(Box)`
  /* margin-top: 20px; */
  display: flex;
  flex-wrap: wrap;
  background-color: transparent;
  justify-content: space-around;
`

const FrecSubContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin: 0;
  width: ${({ width }) => width || '100%'};
  height: fit-content;
`

const FrecText = styled(TextInput)`
  white-space: nowrap;
  overflow: hidden;
  display: block;
  text-overflow: ellipsis;
  max-width: 100%;
`

const FrecRemoveButton = styled(Button)`
  position: ${({ float }) => (float ? 'absolute' : 'relative')};
  top: ${({ float }) => (float ? '-20px' : 'auto')};
  left: ${({ float }) => (float ? '95%' : 'auto')};
  width: ${({ float }) => (float ? '60px' : 'auto')};
  height: ${({ float }) => (float ? '60px' : 'auto')};
  padding: ${({ float }) => (float ? '10px 20px' : '0')};
`

const FrecRemoveImg = styled.img`
  filter: ${({ theme }) => theme.palette.filters.alternative};
  width: 19px;
  height: 19px;
`

const IndicatorsContainer = styled.div`
  display: flex;
  justify-content: space-around;
  margin-bottom: 20px;
`

export default ProductFrec
