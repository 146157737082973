import { Link } from 'react-router-dom'
import styled from 'styled-components'

import { useLanguage } from 'contexts/language'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'

import Box from 'components/box'
import Typography from 'components/typography'
import AnimateChildren from 'react-animate-children'
import {
  getFrecsByProduct,
  getGroupsByProduct,
  getProductSectionData,
  getProductSections,
  postFrecByProduct,
  reorderGroupsByProduct,
} from 'api'
import { useData } from 'contexts/data'

const Sections = ({ product: { code, data: productData } }) => {
  const [language] = useLanguage()
  const [data, setData] = useData()

  const sections = getProductSections(code)

  let sectionsData = getProductSectionData(code, productData)

  const newDynamicFields = [
    {
      Nombre: 'Nuevo Grupo',
      Descripcion: 'Descripción',
      DescripcionAmpliada: 'Descripción Ampliada',
    },
    {
      Nombre: 'Nueva Sesión',
      Observaciones: 'Observaciones',
    },
  ]

  const isSession = section => section === 'Sesiones' || section === 'Facial'

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list)
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)

    return result
  }

  const onDragEnd = ({ draggableId, destination, source, ...rest }) => {
    try {
      if (code !== 'bf') return alert('Solo se puede reordenar en Biofoton')
      const section = sections.find(section => draggableId.includes(section))

      // const sectionGroups = sectionsData[section]?.groups?.[language]?.filter(
      //   Boolean,
      // )

      // const [fromGroup, fromGroupIndex] = [
      //   sectionGroups[source.index],
      //   source.index,
      // ]

      // const fromGroupFrecs = sectionsData[section]?.frecs?.[language]?.filter(
      //   Boolean,
      // )[fromGroupIndex]

      // const from = {
      //   group: fromGroup,
      //   groupIndex: fromGroupIndex,
      //   frecs: fromGroupFrecs,
      // }

      // const [toGroup, toGroupIndex] = [
      //   sectionGroups[destination.index],
      //   destination.index,
      // ]

      // const toGroupFrecs = sectionsData[section]?.frecs?.[language]?.filter(
      //   Boolean,
      // )[toGroupIndex]

      // const to = {
      //   group: toGroup,
      //   groupIndex: toGroupIndex,
      //   frecs: toGroupFrecs,
      // }

      const newGroups = reorder(
        sectionsData[section]?.groups?.[language],
        source.index,
        destination.index,
      )

      const newFrecs = reorder(
        sectionsData[section]?.frecs?.[language],
        source.index,
        destination.index,
      )

      const newData = {
        ...data,
      }

      const originalGroupsKeys = getGroupsByProduct(code, section)
      const originalFrecsKeys = getFrecsByProduct(code, section)

      if (originalGroupsKeys[1]) {
        // Programas
        // Frecuencias
        // const originalGroups =
        //   newData[code][originalGroupsKeys[0]][originalGroupsKeys[1]][language]

        // // move fromGroup from original index to destination index
        // originalGroups.splice(destination.index + 1, 0, from.group)

        // // remove fromGroup from original index
        // originalGroups.splice(source.index, 1)

        // newData[code][originalGroupsKeys[0]][originalGroupsKeys[1]][
        //   language
        // ] = originalGroups

        // const originalFrecs =
        //   newData[code][originalFrecsKeys[0]][originalFrecsKeys[1]][language]

        // // move fromGroupFrecs from original index to destination index
        // originalFrecs.splice(destination.index + 1, 0, from.frecs)

        // // remove fromGroupFrecs from original index
        // originalFrecs.splice(source.index, 1)

        // newData[code][originalFrecsKeys[0]][originalFrecsKeys[1]][
        //   language
        // ] = originalFrecs

        newData[code][originalGroupsKeys[0]][originalGroupsKeys[1]][
          language
        ] = newGroups
        newData[code][originalFrecsKeys[0]][originalFrecsKeys[1]][
          language
        ] = newFrecs
      }

      if (originalGroupsKeys[0] && !originalGroupsKeys[1]) {
        // Sesiones
        newData[code][originalGroupsKeys[0]][language] = newGroups
        newData[code][originalFrecsKeys[0]][language] = newFrecs
      }

      setData(newData)
      sectionsData = getProductSectionData(code, newData)
    } catch (error) {
      console.log(error)
    }
  }

  const getItemStyle = (isDragging, draggableStyle) => ({
    ...draggableStyle,
    paddinBottom: isDragging ? 1 : 1.5,
  })

  return (
    <Container speed={50}>
      {sections.map(section => (
        <div key={`sections-${code}-${section}`}>
          <Title variant="bodylarge">{section}</Title>

          <GroupsContainer>
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="droppable">
                {provided => (
                  <div {...provided.droppableProps} ref={provided.innerRef}>
                    {sectionsData[section]?.groups?.[language]
                      ? sectionsData[section]?.groups[language]
                          ?.filter(Boolean)
                          .map(({ Nombre, Id, ...rest }, index) => (
                            <Draggable
                              key={`section-group-${code}-${section}-${Nombre}`}
                              draggableId={`section-group-${code}-${section}-${Nombre}`}
                              index={index}
                            >
                              {(provided, snapshot) => (
                                <div
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  style={getItemStyle(
                                    snapshot.isDragging,
                                    provided.draggableProps.style,
                                  )}
                                >
                                  <Link
                                    key={`section-group-${code}-${section}-${Nombre}`}
                                    to={`/products/${code}/group/${index}`}
                                    state={{
                                      section,
                                      group: {
                                        Nombre,
                                        Id,
                                        ...rest,
                                        groupFrecsIndex: index,
                                        groupsIndex: index,
                                      },
                                      frecs:
                                        sectionsData[section]?.frecs?.[
                                          language
                                        ][index] || [],
                                      isSession: isSession(section),
                                    }}
                                  >
                                    <GroupTitle variant="bodylarge">
                                      {Nombre}
                                    </GroupTitle>
                                  </Link>
                                </div>
                              )}
                            </Draggable>
                          ))
                      : sectionsData[section]?.frecs[language]
                          ?.filter(Boolean)
                          .map(({ Nombre, Id, ...rest }, index) => (
                            <Draggable
                              key={`section-frec-${code}-${section}-${Nombre}`}
                              draggableId={`section-frec-${code}-${section}-${Nombre}`}
                              index={index}
                            >
                              {(provided, snapshot) => (
                                <div
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  style={getItemStyle(
                                    snapshot.isDragging,
                                    provided.draggableProps.style,
                                  )}
                                >
                                  <Link
                                    key={`section-frec-${code}-${section}-${Nombre}`}
                                    to={`/products/${code}/group/-1/frec/${Id ||
                                      index}`}
                                    state={{
                                      section,
                                      frec: {
                                        Nombre,
                                        Id,
                                        frecIndex: index,
                                        ...rest,
                                      },
                                      isSimpleFrec:
                                        section === 'Frecuencias Simples',
                                    }}
                                  >
                                    <GroupTitle variant="bodylarge">
                                      {Nombre}
                                    </GroupTitle>
                                  </Link>
                                </div>
                              )}
                            </Draggable>
                          ))}

                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>

            {sectionsData[section]?.groups?.[language] ? (
              <Link
                to={`/products/${code}/group/new`}
                state={{
                  section,
                  isNewGroup: true,
                  isSession: isSession(section),
                  group: {
                    ...newDynamicFields[isSession(section) ? 1 : 0],
                    Id:
                      Number(
                        [
                          ...new Set(
                            sectionsData[section]?.groups?.[language]
                              ?.filter(Boolean)
                              .map(({ Id }) => Id),
                          ),
                        ]
                          .sort((a, b) => a - b)
                          .at(-1),
                      ) + 1 || 0,
                    groupFrecsIndex: sectionsData[section]?.groups[language]
                      .length
                      ? sectionsData[section]?.groups[language].length
                      : 0,
                    groupsIndex: sectionsData[section]?.groups[language].length
                      ? sectionsData[section]?.groups[language].length
                      : 0,
                  },
                  frecs: [],
                }}
              >
                <GroupTitle variant="bodylarge">+</GroupTitle>
              </Link>
            ) : (
              <Link
                to={`/products/${code}/group/-1/frec/new`}
                state={{
                  section,
                  group: null,
                  isNewFrec: true,
                  frec: {
                    Nombre: '',
                    Summary: '',
                    Id: '',
                    Code: '',
                    Imagen: '',
                    Mode: '0',
                    Segments: [],
                    Segundos: '0',
                    frecIndex: sectionsData[section]?.frecs[language].length,
                  },
                }}
              >
                <GroupTitle variant="bodylarge">+</GroupTitle>
              </Link>
            )}
          </GroupsContainer>
        </div>
      ))}
    </Container>
  )
}

const Container = styled(AnimateChildren)``

const Title = styled(Typography)`
  padding: 10px 0;
`

const GroupsContainer = styled(Box)`
  flex-wrap: wrap;
  /* display: flex; */
`

const GroupTitle = styled(Typography)`
  margin: 5px;

  cursor: pointer;
  transition: color 0.3s;

  &:hover {
    color: ${({ theme }) => theme.palette.alternative};
  }
`

export default Sections
