import React from 'react'

import styled from 'styled-components'

const Box = ({
  children,
  variant = 'whites',
  radius = 20,
  p = 20,
  m = 0,
  className = '',
}) => {
  return (
    <Container {...{ variant, radius, p, m, className }}>{children}</Container>
  )
}

const Container = styled.div`
  background-color: ${({ theme, variant }) => theme.palette[variant][5]};
  padding: ${({ p }) => `${p / 1.5}px ${p}px`};
  margin: ${({ m }) => `${m}px`};
  border-radius: ${({ radius }) => `${radius}px`};
  box-shadow: 0px 15px 30px 0px #0000000d;
`

export default Box
