import React from 'react'

import ContextsProviders from 'contexts'
import StylesProvider from 'style'

import FontStyles from 'style/globalStyle'

const Providers = ({ children }) => (
  <StylesProvider>
    <ContextsProviders>
      <FontStyles />

      {children}
    </ContextsProviders>
  </StylesProvider>
)

export default Providers
