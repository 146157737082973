import { useEffect, useState } from 'react'

import styled from 'styled-components'
import { useParams } from 'react-router-dom'

import { useData } from 'contexts/data'
import { useSimpleFrecs } from 'contexts/simplefrecs'
import { getProductImage, getProductName } from 'api'

import AnimateChildren from 'react-animate-children'

import Sections from './sections'
import Box from 'components/box'
import Typography from 'components/typography'

const ProductDashboard = () => {
  const { product } = useParams()

  const productName = getProductName(product)

  const [data] = useData()
  const [simpleFrecs] = useSimpleFrecs()

  const productData = product === 'fs' ? simpleFrecs : data && data[product]

  const [visible, setVisible] = useState(false)

  useEffect(() => {
    setTimeout(() => {
      setVisible(true)
    }, 500)

    return () => {
      setVisible(false)
    }
  }, [product])

  if (!visible) {
    return <></>
  }

  return (
    <Container speed={50}>
      {!productData ? (
        <NoDataContainer>
          <Typography variant="bodylarge">
            Todavía no hay data. Importa un JSON para {productName}
          </Typography>
        </NoDataContainer>
      ) : (
        <Sections
          product={{
            code: product,
            name: productName,
            data: productData,
          }}
        />
      )}
    </Container>
  )
}

const Container = styled(AnimateChildren)``

const NoDataContainer = styled(Box)`
  margin-top: 20px;
`

export default ProductDashboard
