import React, { createContext } from 'react'
import { useLocalStorage } from 'hooks/useLocalStorage'

export const UserContext = createContext()
UserContext.displayName = 'UserContext'

export const UserProvider = props => {
  const [user, setUser] = useLocalStorage('@user', false)
  const value = [user, setUser]

  return <UserContext.Provider value={value} {...props} />
}

export const useUser = () => {
  const context = React.useContext(UserContext)

  if (!context) {
    throw new Error('useUser must be used within a UserProvider')
  }

  return context
}
