import biofoton from 'assets/biofoton-text.png'
import galaxfamily from 'assets/galaxfamily-text.png'
import lightbeauty from 'assets/lightbeauty-text.png'
import odonlight from 'assets/odonlight-text.png'
import expert from 'assets/expert.png'

export const getProductName = productCode =>
  ({
    bf: 'Biofoton 2',
    gf: 'Galax Family',
    lb: 'Light Beauty',
    ol: 'Odon Light',
    fs: 'Frecuencias Simples',
    se: 'Sistema Experto',
  }[productCode])

export const getProductImage = productCode =>
  ({
    bf: biofoton,
    gf: galaxfamily,
    lb: lightbeauty,
    ol: odonlight,
  }[productCode])

export const getProductSections = productCode =>
  ({
    bf: ['Programas', 'Frecuencias', 'Sesiones'],
    gf: ['Emociones', 'Sesiones'],
    lb: ['Local', 'Emociones', 'Facial'],
    ol: ['Sesiones', 'Emociones'],
    fs: ['Frecuencias Simples'],
    se: ['Mental', 'Emocional', 'Físico'],
  }[productCode])

export const getProductSectionData = (productCode, data) =>
  ({
    bf: {
      Programas: {
        groups: data?.getgroups?.programs,
        frecs: data?.getfrecsbygroup?.programs,
      },
      Frecuencias: {
        groups: data?.getgroups?.frecuencies,
        frecs: data?.getfrecsbygroup?.frecuencies,
      },
      Sesiones: {
        groups: data?.getsessions,
        frecs: data?.getfrecsbysession,
      },
    },
    gf: {
      Emociones: {
        frecs: data?.getemotions,
      },
      Sesiones: {
        groups: data?.getsessions,
        frecs: data?.getfrecsbysession,
      },
    },
    lb: {
      Local: {
        frecs: data?.local,
      },
      Emociones: {
        frecs: data?.emotions,
      },
      Facial: {
        groups: data?.facial,
        frecs: data?.facial,
      },
    },
    ol: {
      Sesiones: {
        groups: data?.getsessions,
        frecs: data?.getfrecsbysession,
      },
      Emociones: {
        frecs: data?.getemotions,
      },
    },
    se: {
      Mental: {
        groups: data?.getgroups?.mental,
        frecs: data?.getfrecsbygroup?.mental,
      },
      Emocional: {
        groups: data?.getgroups?.emotional,
        frecs: data?.getfrecsbygroup?.emotional,
      },
      Físico: {
        groups: data?.getgroups?.physical,
        frecs: data?.getfrecsbygroup?.physical,
      },
    },
    fs: {
      'Frecuencias Simples': data,
    },
  }[productCode])

export const getGroupsByProduct = (productCode, sectionName) =>
  ({
    bf: {
      Programas: ['getgroups', 'programs'],
      Frecuencias: ['getgroups', 'frecuencies'],
      Sesiones: ['getsessions'],
    },
    gf: { Sesiones: ['getsessions'] },
    lb: { Facial: ['facial'] },
    ol: { Sesiones: ['getsessions'] },
    se: {
      Mental: ['getgroups', 'mental'],
      Emocional: ['getgroups', 'emotional'],
      Físico: ['getgroups', 'physical'],
    },
  }[productCode][sectionName])

export const getFrecsByProduct = (productCode, sectionName) =>
  ({
    bf: {
      Programas: ['getfrecsbygroup', 'programs'],
      Frecuencias: ['getfrecsbygroup', 'frecuencies'],
      Sesiones: ['getfrecsbysession'],
    },
    gf: { Emociones: ['getemotions'], Sesiones: ['getfrecsbysession'] },
    lb: { Local: ['local'], Emociones: ['emotions'], Facial: ['facial'] },
    ol: { Emociones: ['getemotions'], Sesiones: ['getfrecsbysession'] },
    se: {
      Mental: ['getfrecsbygroup', 'mental'],
      Emocional: ['getfrecsbygroup', 'emotional'],
      Físico: ['getfrecsbygroup', 'physical'],
    },
    fs: { 'Frecuencias Simples': ['frecs'] },
  }[productCode][sectionName])

export const postGroupsByProduct = (
  data,
  product,
  groupsByProduct,
  frecsByProduct,
  language,
  group,
  newGroup,
) => {
  const newData = {
    ...data,
  }

  if (groupsByProduct[1]) {
    newData[product][groupsByProduct[0]][groupsByProduct[1]][language][
      group.groupsIndex
    ] = newGroup

    if (
      frecsByProduct[1] &&
      !newData[product][frecsByProduct[0]][frecsByProduct[1]][language][
        group.groupsIndex
      ]
    ) {
      newData[product][frecsByProduct[0]][frecsByProduct[1]][language][
        group.groupsIndex
      ] = []
    }

    return newData
  }

  if (!groupsByProduct[1]) {
    newData[product][groupsByProduct[0]][language][group.groupsIndex] = newGroup

    if (
      frecsByProduct[1] &&
      !newData[product][frecsByProduct[0]][frecsByProduct[1]][language][
        group.groupsIndex
      ]
    ) {
      newData[product][frecsByProduct[0]][frecsByProduct[1]][language][
        group.groupsIndex
      ] = []
    }

    return newData
  }
}

export const reorderGroupsByProduct = (
  data,
  product,
  groupsByProduct,
  frecsByProduct,
  language,
  group,
  newGroup,
) => {
  const newData = {
    ...data,
  }
}

export const reorderGroupFrecsByProduct = (
  data,
  product,
  groupsByProduct,
  frecsByProduct,
  language,
  group,
  newGroup,
  newGroupFrecs,
) => {
  const newData = {
    ...data,
  }

  if (groupsByProduct[1]) {
    newData[product][groupsByProduct[0]][groupsByProduct[1]][language][
      group.groupsIndex
    ] = newGroup

    if (frecsByProduct[1]) {
      newData[product][frecsByProduct[0]][frecsByProduct[1]][language][
        group.groupsIndex
      ] = newGroupFrecs
    }

    return newData
  }

  if (!groupsByProduct[1]) {
    newData[product][groupsByProduct[0]][language][group.groupsIndex] = newGroup

    if (
      frecsByProduct[1] &&
      !newData[product][frecsByProduct[0]][frecsByProduct[1]][language][
        group.groupsIndex
      ]
    ) {
      newData[product][frecsByProduct[0]][frecsByProduct[1]][language][
        group.groupsIndex
      ] = newGroupFrecs
    } else {
      if (product === 'lb') {
        // newData[product][frecsByProduct[0]][language][
        //   group.groupsIndex
        // ] = newGroupFrecs
      } else {
        newData[product][frecsByProduct[0]][language][
          group.groupsIndex
        ] = newGroupFrecs
      }
    }

    return newData
  }
}

export const removeGroupsByProduct = (
  data,
  product,
  groupsByProduct,
  frecsByProduct,
  language,
  group,
) => {
  const newData = {
    ...data,
  }

  if (groupsByProduct[1]) {
    const groupIndex = newData[product][groupsByProduct[0]][groupsByProduct[1]][
      language
    ].findIndex(({ Id }) => Id === group.Id)

    newData[product][groupsByProduct[0]][groupsByProduct[1]][language].splice(
      groupIndex,
      1,
    )

    if (frecsByProduct[1]) {
      newData[product][frecsByProduct[0]][frecsByProduct[1]][language].splice(
        group.groupsIndex,
        1,
      )
    }

    return newData
  }

  if (!groupsByProduct[1]) {
    const groupIndex = newData[product][groupsByProduct[0]][language].findIndex(
      ({ Id }) => Id === group.Id,
    )

    newData[product][groupsByProduct[0]][language].splice(groupIndex, 1)

    if (!frecsByProduct[1]) {
      newData[product][frecsByProduct[0]][language].splice(group.groupsIndex, 1)
    }

    return newData
  }
}

export const postFrecByProduct = (
  data,
  product,
  frecsByProduct,
  language,
  group,
  frec,
  newFrec,
) => {
  const newData = {
    ...data,
  }

  if (!group) {
    newData[product][frecsByProduct[0]][language][frec.frecIndex] = newFrec
    return newData
  }

  if (group) {
    if (frecsByProduct[1]) {
      newData[product][frecsByProduct[0]][frecsByProduct[1]][language][
        group.groupFrecsIndex
      ][frec.frecIndex] = newFrec

      return newData
    }

    if (!frecsByProduct[1]) {
      if (product === 'lb') {
        if (
          !newData[product][frecsByProduct[0]][language][group.groupFrecsIndex]
            .frequencies
        ) {
          newData[product][frecsByProduct[0]][language][
            group.groupFrecsIndex
          ].frequencies = []
        }

        newData[product][frecsByProduct[0]][language][
          group.groupFrecsIndex
        ].frequencies[frec.frecIndex] = newFrec

        return newData
      }

      if (
        !newData[product][frecsByProduct[0]][language][group.groupFrecsIndex]
      ) {
        newData[product][frecsByProduct[0]][language][
          group.groupFrecsIndex
        ] = []
      }

      newData[product][frecsByProduct[0]][language][group.groupFrecsIndex][
        frec.frecIndex
      ] = newFrec

      return newData
    }
  }
}

export const removeFrecByProduct = (
  data,
  product,
  frecsByProduct,
  language,
  group,
  frec,
) => {
  const newData = {
    ...data,
  }

  if (!group) {
    newData[product][frecsByProduct[0]][language].splice(frec.frecIndex, 1)
    return newData
  }

  if (group) {
    if (frecsByProduct[1]) {
      newData[product][frecsByProduct[0]][frecsByProduct[1]][language][
        group.groupFrecsIndex
      ].splice(frec.frecIndex, 1)

      return newData
      return newData[product][frecsByProduct[0]][frecsByProduct[1]][language][
        group.groupFrecsIndex
      ]
    }

    if (!frecsByProduct[1]) {
      if (product === 'lb') {
        newData[product][frecsByProduct[0]][language][
          group.groupFrecsIndex
        ].frequencies.splice(frec.frecIndex, 1)

        return newData
        return newData[product][frecsByProduct[0]][language][
          group.groupFrecsIndex
        ].frequencies
      }

      newData[product][frecsByProduct[0]][language][
        group.groupFrecsIndex
      ].splice(frec.frecIndex, 1)

      return newData
      return newData[product][frecsByProduct[0]][language][
        group.groupFrecsIndex
      ]
    }
  }
}
