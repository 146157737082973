import styled from 'styled-components'

import Box from 'components/box'
import Typography from 'components/typography'

const SectionHeader = ({ image, title, subtitle }) => {
  return (
    <Container>
      {image ? <Image src={image} alt="" /> : null}
      {title ? <Typography variant="subtitle">{title}</Typography> : null}
      {subtitle ? <Title variant="sectiontitle">{subtitle}</Title> : null}
    </Container>
  )
}

const Container = styled(Box)`
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  flex: 1;
`

const Image = styled.img`
  width: 500px;
  height: 190px;
  object-fit: contain;

  @media screen and (max-width: 468px) {
    width: 90%;
  }
`

const Title = styled(Typography)`
  @media screen and (max-width: 468px) {
    font-size: 15px;
  }
`

export default SectionHeader
